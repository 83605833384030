export const countryStateMapping = {
    "Andorra": {
        "countryCode": "AD",
        statesList: {}
    },
    "United Arab Emirates": {
        "countryCode": "AE",
        statesList: {}
    },
    "Afghanistan": {
        "countryCode": "AF",
        statesList: {}
    },
    "Anguilla": {
        "countryCode": "AI",
        statesList: {}
    },
    "Albania": {
        "countryCode": "AL",
        statesList: {}
    },
    "Armenia": {
        "countryCode": "AM",
        statesList: {}
    },
    "Angola": {
        "countryCode": "AO",
        statesList: {}
    },
    "Argentina": {
        "countryCode": "AR",
        statesList: {}
    },
    "Austria": {
        "countryCode": "AT",
        statesList: {}
    },
    "Australia": {
        "countryCode": "AU",
        statesList: {
            "Australian Capital Territory": "AU-ACT",
            "New South Wales": "AU-NSW",
            "Northern Territory": "AU-NT",
            "Queensland": "AU-QLD",
            "South Australia": "AU-SA",
            "Tasmania": "AU-TAS",
            "Victoria": "AU-VIC",
            "Western Australia": "AU-WA"
        }
    },
    "Aruba": {
        "countryCode": "AW",
        statesList: {}
    },
    "Azerbaijan": {
        "countryCode": "AZ",
        statesList: {}
    },
    "Bosnia": {
        "countryCode": "BA",
        statesList: {}
    },
    "Barbados": {
        "countryCode": "BB",
        statesList: {}
    },
    "Bangladesh": {
        "countryCode": "BD",
        statesList: {}
    },
    "Belgium": {
        "countryCode": "BE",
        statesList: {}
    },
    "Bulgaria": {
        "countryCode": "BG",
        statesList: {}
    },
    "Bahrain": {
        "countryCode": "BH",
        statesList: {}
    },
    "Benin": {
        "countryCode": "BJ",
        statesList: {}
    },
    "Bermuda": {
        "countryCode": "BM",
        statesList: {}
    },
    "Brunei Darussalam": {
        "countryCode": "BN",
        statesList: {}
    },
    "Bolivia": {
        "countryCode": "BO",
        statesList: {}
    },
    "Brazil": {
        "countryCode": "BR",
        statesList: {}
    },
    "Bahamas": {
        "countryCode": "BS",
        statesList: {}
    },
    "Bhutan": {
        "countryCode": "BT",
        statesList: {}
    },
    "Botswana": {
        "countryCode": "BW",
        statesList: {}
    },
    "Belarus": {
        "countryCode": "BY",
        statesList: {}
    },
    "Belize": {
        "countryCode": "BZ",
        statesList: {}
    },
    "Canada": {
        "countryCode": "CA",
        statesList: {
            "Alberta": "CA-AB",
            "British Columbia": "CA-BC",
            "Manitoba": "CA-MB",
            "New Brunswick": "CA-NB",
            "Newfoundland and Labrador": "CA-NL",
            "Nova Scotia": "CA-NS",
            "Northwest Territories": "CA-NT",
            "Nunavut": "CA-NU",
            "Ontario": "CA-ON",
            "Prince Edward Island": "CA-PE",
            "Quebec": "CA-QC",
            "Saskatchewan": "CA-SK",
            "Yukon Territory": "CA-YT"
        }
    },
    "Switzerland": {
        "countryCode": "CH",
        statesList: {}
    },
    "Ivory Coast": {
        "countryCode": "CI",
        statesList: {}
    },
    "Chile": {
        "countryCode": "CL",
        statesList: {}
    },
    "Cameroon": {
        "countryCode": "CM",
        statesList: {}
    },
    "China": {
        "countryCode": "CN",
        statesList: {}
    },
    "Colombia": {
        "countryCode": "CO",
        statesList: {}
    },
    "Costa Rica": {
        "countryCode": "CR",
        statesList: {}
    },
    "Curacao": {
        "countryCode": "CW",
        statesList: {}
    },
    "Cyprus": {
        "countryCode": "CY",
        statesList: {}
    },
    "Czech Republic": {
        "countryCode": "CZ",
        statesList: {}
    },
    "Germany": {
        "countryCode": "DE",
        statesList: {}
    },
    "Denmark": {
        "countryCode": "DK",
        statesList: {}
    },
    "Dominican Republic": {
        "countryCode": "DO",
        statesList: {}
    },
    "Algeria": {
        "countryCode": "DZ",
        statesList: {}
    },
    "Ecuador": {
        "countryCode": "EC",
        statesList: {}
    },
    "Estonia": {
        "countryCode": "EE",
        statesList: {}
    },
    "Egypt": {
        "countryCode": "EG",
        statesList: {}
    },
    "Spain": {
        "countryCode": "ES",
        statesList: {}
    },
    "Finland": {
        "countryCode": "FI",
        statesList: {}
    },
    "France": {
        "countryCode": "FR",
        statesList: {}
    },
    "Gabon": {
        "countryCode": "GA",
        statesList: {}
    },
    "United Kingdom": {
        "countryCode": "GB",
        statesList: {}
    },
    "Grenada": {
        "countryCode": "GD",
        statesList: {}
    },
    "Georgia": {
        "countryCode": "GE",
        statesList: {}
    },
    "Guernsey": {
        "countryCode": "GG",
        statesList: {}
    },
    "Ghana": {
        "countryCode": "GH",
        statesList: {}
    },
    "Gibraltar": {
        "countryCode": "GI",
        statesList: {}
    },
    "Gambia": {
        "countryCode": "GM",
        statesList: {}
    },
    "Guatemala": {
        "countryCode": "GT",
        statesList: {}
    },
    "Greece": {
        "countryCode": "GR",
        statesList: {}
    },
    "Guyana": {
        "countryCode": "GY",
        statesList: {}
    },
    "Hong Kong": {
        "countryCode": "HK",
        statesList: {}
    },
    "Honduras": {
        "countryCode": "HN",
        statesList: {}
    },
    "Croatia": {
        "countryCode": "HR",
        statesList: {}
    },
    "Hungary": {
        "countryCode": "HU",
        statesList: {}
    },
    "Indonesia": {
        "countryCode": "ID",
        statesList: {}
    },
    "Ireland": {
        "countryCode": "IE",
        statesList: {}
    },
    "Israel": {
        "countryCode": "IL",
        statesList: {}
    },
    "Isle of Man": {
        "countryCode": "IM",
        statesList: {}
    },
    "India": {
        "countryCode": "IN",
        statesList: {}
    },
    "Iran": {
        "countryCode": "IR",
        statesList: {}
    },
    "Iceland": {
        "countryCode": "IS",
        statesList: {}
    },
    "Italy": {
        "countryCode": "IT",
        statesList: {}
    },
    "Jersey": {
        "countryCode": "JE",
        statesList: {}
    },
    "Jamaica": {
        "countryCode": "JM",
        statesList: {}
    },
    "Jordan": {
        "countryCode": "JO",
        statesList: {}
    },
    "Japan": {
        "countryCode": "JP",
        statesList: {}
    },
    "Kenya": {
        "countryCode": "KE",
        statesList: {}
    },
    "Cambodia": {
        "countryCode": "KH",
        statesList: {}
    },
    "Kiribati": {
        "countryCode": "KI",
        statesList: {}
    },
    "South Korea": {
        "countryCode": "KR",
        statesList: {}
    },
    "Kuwait": {
        "countryCode": "KW",
        statesList: {}
    },
    "Cayman Islands": {
        "countryCode": "KY",
        statesList: {}
    },
    "Kazakhstan": {
        "countryCode": "KZ",
        statesList: {}
    },
    "Laos": {
        "countryCode": "LA",
        statesList: {}
    },
    "Lebanon": {
        "countryCode": "LB",
        statesList: {}
    },
    "Liechtenstein": {
        "countryCode": "LI",
        statesList: {}
    },
    "Sri Lanka": {
        "countryCode": "LK",
        statesList: {}
    },
    "Lesotho": {
        "countryCode": "LS",
        statesList: {}
    },
    "Lithuania": {
        "countryCode": "LT",
        statesList: {}
    },
    "Luxembourg": {
        "countryCode": "LU",
        statesList: {}
    },
    "Latvia": {
        "countryCode": "LV",
        statesList: {}
    },
    "Libya": {
        "countryCode": "LY",
        statesList: {}
    },
    "Morocco": {
        "countryCode": "MA",
        statesList: {}
    },
    "Monaco": {
        "countryCode": "MC",
        statesList: {}
    },
    "Montenegro": {
        "countryCode": "ME",
        statesList: {}
    },
    "Macedonia": {
        "countryCode": "MK",
        statesList: {}
    },
    "Myanmar": {
        "countryCode": "MM",
        statesList: {}
    },
    "Mongolia": {
        "countryCode": "MN",
        statesList: {}
    },
    "Macau": {
        "countryCode": "MO",
        statesList: {}
    },
    "Malta": {
        "countryCode": "MT",
        statesList: {}
    },
    "Mauritius": {
        "countryCode": "MU",
        statesList: {}
    },
    "Malawi": {
        "countryCode": "MW",
        statesList: {}
    },
    "Mexico": {
        "countryCode": "MX",
        statesList: {}
    },
    "Malaysia": {
        "countryCode": "MY",
        statesList: {}
    },
    "Mozambique": {
        "countryCode": "MZ",
        statesList: {}
    },
    "Namibia": {
        "countryCode": "NA",
        statesList: {}
    },
    "Nigeria": {
        "countryCode": "NG",
        statesList: {}
    },
    "Nicaragua": {
        "countryCode": "NI",
        statesList: {}
    },
    "Netherlands": {
        "countryCode": "NL",
        statesList: {}
    },
    "New Zealand": {
        "countryCode": "NZ",
        statesList: {}
    },
    "Norway": {
        "countryCode": "NO",
        statesList: {}
    },
    "Nepal": {
        "countryCode": "NP",
        statesList: {}
    },
    "Oman": {
        "countryCode": "OM",
        statesList: {}
    },
    "Panama": {
        "countryCode": "PA",
        statesList: {}
    },
    "Peru": {
        "countryCode": "PE",
        statesList: {}
    },
    "Philippines": {
        "countryCode": "PH",
        statesList: {}
    },
    "Pakistan": {
        "countryCode": "PK",
        statesList: {}
    },
    "Poland": {
        "countryCode": "PL",
        statesList: {}
    },
    "Portugal": {
        "countryCode": "PT",
        statesList: {}
    },
    "Paraguay": {
        "countryCode": "PY",
        statesList: {}
    },
    "Qatar": {
        "countryCode": "QA",
        statesList: {}
    },
    "Romania": {
        "countryCode": "RO",
        statesList: {}
    },
    "Serbia": {
        "countryCode": "RS",
        statesList: {}
    },
    "Russia": {
        "countryCode": "RU",
        statesList: {}
    },
    "Rwanda": {
        "countryCode": "RW",
        statesList: {}
    },
    "Saudi Arabia": {
        "countryCode": "SA",
        statesList: {}
    },
    "Sweden": {
        "countryCode": "SE",
        statesList: {}
    },
    "Singapore": {
        "countryCode": "SG",
        statesList: {}
    },
    "Slovenia": {
        "countryCode": "SI",
        statesList: {}
    },
    "Slovakia": {
        "countryCode": "SK",
        statesList: {}
    },
    "San Marino": {
        "countryCode": "SM",
        statesList: {}
    },
    "Senegal": {
        "countryCode": "SN",
        statesList: {}
    },
    "El Salvador": {
        "countryCode": "SV",
        statesList: {}
    },
    "Swaziland": {
        "countryCode": "SZ",
        statesList: {}
    },
    "Thailand": {
        "countryCode": "TH",
        statesList: {}
    },
    "Timor-Leste": {
        "countryCode": "TL",
        statesList: {}
    },
    "Tunisia": {
        "countryCode": "TN",
        statesList: {}
    },
    "Turkey": {
        "countryCode": "TR",
        statesList: {}
    },
    "Trinidad & Tobago": {
        "countryCode": "TT",
        statesList: {}
    },
    "Taiwan": {
        "countryCode": "TW",
        statesList: {}
    },
    "Tanzania": {
        "countryCode": "TZ",
        statesList: {}
    },
    "Ukraine": {
        "countryCode": "UA",
        statesList: {}
    },
    "Uganda": {
        "countryCode": "UG",
        statesList: {}
    },
    "United States": {
        "countryCode": "US",
        statesList: {
            "Alaska": "US-AK",
            "Alabama": "US-AL",
            "Arkansas": "US-AR",
            "American Samoa": "US-AS",
            "Arizona": "US-AZ",
            "California": "US-CA",
            "Colorado": "US-CO",
            "Connecticut": "US-CT",
            "District of Columbia": "US-DC",
            "Delaware": "US-DE",
            "Florida": "US-FL",
            "Georgia": "US-GA",
            "Guam": "US-GU",
            "Hawaii": "US-HI",
            "Iowa": "US-IA",
            "Idaho": "US-ID",
            "Illinois": "US-IL",
            "Indiana": "US-IN",
            "Kansas": "US-KS",
            "Kentucky": "US-KY",
            "Louisiana": "US-LA",
            "Massachusetts": "US-MA",
            "Maryland": "US-MD",
            "Maine": "US-ME",
            "Michigan": "US-MI",
            "Minnesota": "US-MN",
            "Missouri": "US-MO",
            "Northern Mariana Islands": "US-MP",
            "Mississippi": "US-MS",
            "Montana": "US-MT",
            "North Carolina": "US-NC",
            "North Dakota": "US-ND",
            "Nebraska": "US-NE",
            "New Hampshire": "US-NH",
            "New Jersey": "US-NJ",
            "New Mexico": "US-NM",
            "Nevada": "US-NV",
            "New York": "US-NY",
            "Ohio": "US-OH",
            "Oklahoma": "US-OK",
            "Oregon": "US-OR",
            "Pennsylvania": "US-PA",
            "Puerto Rico": "US-PR",
            "Rhode Island": "US-RI",
            "South Carolina": "US-SC",
            "South Dakota": "US-SD",
            "Tennessee": "US-TN",
            "Texas": "US-TX",
            "United States Minor Outlying Islands": "US-UM",
            "Utah": "US-UT",
            "Virginia": "US-VA",
            "American Virgin Islands": "US-VI",
            "Vermont": "US-VT",
            "Washington": "US-WA",
            "Wisconsin": "US-WI",
            "West Virginia": "US-WV",
            "Wyoming": "US-WY"
        }
    },
    "Uruguay": {
        "countryCode": "UY",
        statesList: {}
    },
    "Uzbekistan": {
        "countryCode": "UZ",
        statesList: {}
    },
    "Vatican City": {
        "countryCode": "VA",
        statesList: {}
    },
    "Venezuela": {
        "countryCode": "VE",
        statesList: {}
    },
    "British Virgin Islands": {
        "countryCode": "VG",
        statesList: {}
    },
    "Vietnam": {
        "countryCode": "VN",
        statesList: {}
    },
    "Yemen": {
        "countryCode": "YE",
        statesList: {}
    },
    "South Africa": {
        "countryCode": "ZA",
        statesList: {}
    },
    "Zambia": {
        "countryCode": "ZM",
        statesList: {}
    },
    "Zimbabwe": {
        "countryCode": "ZW",
        statesList: {}
    }
}
